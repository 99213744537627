<template>
  <base-section id="theme-features">
    <base-section-heading title="Nos Principes fondateurs" />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.content }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPrincipeFondateurs',

    data: () => ({
      features: [
        {
          title: 'Organisme d’intérêt général',
          icon: 'mdi-earth',
          color: 'primary',
          dark: true,
          content: 'Le Fonds est une structure ayant vocation à sanctuariser le capital et l’indépendance d’éditeurs ou de groupes de presse et à réaliser une mission d\'intérêt général, utile à la collectivité',
        },
        {
          title: 'But non lucratif',
          icon: 'mdi-charity',
          color: 'primary',
          dark: true,
          content: 'Le Fonds est un organisme à caractère philanthropique n’ayant pas pour objet la poursuite d’un but économique',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Liberté de la presse',
          icon: 'mdi-fountain-pen-tip',
          content: 'Le Fonds s’emploie à soutenir une information libre et pluraliste, contribuant à l’autonomie et la liberté de choix des citoyen, condition d’une démocratie véritable',
        },
        {
          title: 'Indépendance des journaliste',
          icon: 'mdi-account-voice',
          dark: true,
          color: 'primary',
          content: 'Le Fonds défend un journalisme indépendant des pouvoirs et de groupes de pression, au service du bien commun, aux valeurs humanistes et démocratique',
        },
      ],
    }),
  }
</script>
